import { useState } from "react"
import styled from "styled-components"

import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchNoiseMonitoringPresets,
  usePostNoiseMonitoringPresets,
} from "src/data/profileSettings/queries/monitoringPresetQueries"
import { TPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { ComboboxStateful } from "src/ui/Combobox/ComboboxStateful"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

type CreatePresetDialogProps = Pick<TMDialogProps, "onClose" | "open">

export function CreatePresetDialog({ open, onClose }: CreatePresetDialogProps) {
  const { noise_profiles_released } = useBackendFlags()
  const { orgId } = useOrganization()

  const [searchText, setSearchText] = useState("")

  const fetchNoiseMonitoringPresets = useFetchNoiseMonitoringPresets({
    orgId,
    enabled: noise_profiles_released,
    ...(searchText && { filters: { name: searchText } }),
  })

  const presets = fetchNoiseMonitoringPresets.data?.profiles || []

  if (
    !open ||
    !noise_profiles_released ||
    fetchNoiseMonitoringPresets.isInitialLoading
  ) {
    return null
  }

  return (
    <CreatePresetDialogOpen
      presets={presets}
      initialPreset={presets[0]}
      open={open}
      onClose={onClose}
      setSearchText={setSearchText}
    />
  )
}

function CreatePresetDialogOpen({
  presets,
  open,
  onClose,
  initialPreset,
  setSearchText,
}: CreatePresetDialogProps & {
  presets: TPreset[]
  initialPreset?: TPreset
  setSearchText: (input: string) => void
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()
  const { orgId } = useOrganization()

  const [selectedBasePreset, setSelectedBasePreset] = useState<
    TPreset | undefined
  >(initialPreset)
  const [presetName, setPresetName] = useState("")
  const postNoiseMonitoringPresets = usePostNoiseMonitoringPresets({ orgId })

  function handleCreatePreset() {
    if (!selectedBasePreset || !presetName) return

    selectedBasePreset.id &&
      postNoiseMonitoringPresets.mutate(
        {
          name: presetName,
          copy_from_profile_id: selectedBasePreset.id,
        },
        {
          onSuccess(data) {
            onClose()
            navigate(Routes.SettingsPresetsNoiseEdit.location(data.id))
          },
        }
      )
  }

  return (
    <MDialog
      title={t(langKeys.presets_create_new_preset)}
      description={t(langKeys.presets_create_new_preset_description)}
      onConfirm={handleCreatePreset}
      confirmLabel={t(langKeys.presets_create_new_preset_action)}
      confirmButtonProps={{
        loading: postNoiseMonitoringPresets.isLoading,
        type: "submit",
        form: FORM_ID,
      }}
      onClose={onClose}
      open={open}
      hideClose
    >
      <PresetDialogContainer onSubmit={(e) => e.preventDefault()} id={FORM_ID}>
        <ComboboxStateful
          label={t(langKeys.presets_create_new_preset_based_on)}
          options={presets.map((p) => ({ label: p.name, value: p.id }))}
          selectedValue={selectedBasePreset?.id || ""}
          onChange={(selectedValue: string): void => {
            setSelectedBasePreset(
              presets.find((preset) => preset.id === selectedValue)!
            )
          }}
          onSearch={(input) => setSearchText(input)}
          initialInput={selectedBasePreset?.name}
          required
        />

        <MTextField
          label={t(langKeys.presets_create_new_preset_name)}
          required
          value={presetName}
          onChange={(val) => setPresetName(val)}
        />
      </PresetDialogContainer>
    </MDialog>
  )
}

const PresetDialogContainer = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const FORM_ID = "create-monitoring-preset-form"
